import { useState } from "react";
import { CardList, ProgressBar, InputGroup } from "@blueprintjs/core";
import "./App.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

import Row from "./components/Row";
import Nav from "./components/Nav";
import SelectFilter from "./components/SelectFilter";
import { useDebugInformation, useGoogleAuth } from "./components/hooks/async";
import AuthError from "./components/auth/AuthError";
import AccessError from "./components/auth/AccessError";
import ApiError from "./components/auth/ApiError";

const IS_BROWSER_ENGLISH = navigator.language.includes("en");

function formatSearchTerm(value) {
  if (!value) return;
  return value.toLowerCase().trim();
}

function App() {
  const [search, setSearch] = useState("");
  const [showEnglish, setShowEnglish] = useState(false);
  const [allOpen, setAllOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedCm, setSelectedCm] = useState("");

  const { rows, apiError, tokenError } = useDebugInformation();
  const { user, authLoading, authError } = useGoogleAuth();

  const toggleRowsOpen = () => setAllOpen(!allOpen);

  function distinctCms() {
    let cmList = [];
    if (!rows) return cmList;

    rows.forEach((row) => {
      cmList = cmList.concat(row.cms);
    });
    return [...new Set(cmList)];
  }

  function distinctProducts() {
    if (!rows) return [];
    return [...new Set(rows.map((r) => r.name))];
  }

  function searchFilter(row) {
    // ex. FPC-002
    const _search = formatSearchTerm(search);
    if (!_search) return true;
    if (formatSearchTerm(row.code).includes(_search)) return true;
  }

  function productFilter(row) {
    // ex. Fuse1+
    if (!selectedProduct) return true;
    return selectedProduct === row.name;
  }

  function cmFilter(row) {
    // ex. UMC
    if (!selectedCm) return true;
    return row.cms.includes(selectedCm);
  }

  function filteredRows() {
    let filtered = rows;
    if (selectedCm) {
      filtered = filtered.filter(cmFilter);
    }
    if (selectedProduct) {
      filtered = filtered.filter(productFilter);
    }
    if (search) {
      filtered = filtered.filter(searchFilter);
    }
    return filtered;
  }

  let content = null;
  if (tokenError) {
    content = <AccessError />;
  } else if (authError) {
    content = <AuthError />;
  } else if (apiError) {
    content = <ApiError />;
  } else if (authLoading || rows === null) {
    content = <ProgressBar intent="primary" />;
  } else {
    content = (
      <CardList bordered={false} id="list">
        {filteredRows().map((row) => (
          <Row
            key={`${row.code}-${row.name}`}
            row={row}
            showEnglish={showEnglish}
            allOpen={allOpen}
          />
        ))}
      </CardList>
    );
  }

  return (
    <div id="app-container" className="bp5-dark">
      <Nav
        handleToggleRows={toggleRowsOpen}
        isBrowserEnglish={IS_BROWSER_ENGLISH}
        showEnglish={showEnglish}
        setShowEnglish={setShowEnglish}
        user={tokenError || authError || apiError ? null : user}
      >
        <SelectFilter
          prompt="Select product..."
          products={distinctProducts()}
          product={selectedProduct}
          handleSelect={setSelectedProduct}
        />
        <SelectFilter
          prompt="Select cm..."
          products={distinctCms()}
          product={selectedCm}
          handleSelect={setSelectedCm}
        />
        <InputGroup
          id="search"
          leftIcon="filter"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Filter failures..."
          value={search}
        />
      </Nav>
      {content}
    </div>
  );
}

export default App;
